import { ColDefinitionDetails } from '../../components';
import { GetColab } from '../../modules/Colab/types';
import { useUserContext } from '../../modules/Users/UserContext';
import { DetailsSection, DetailsSectionHeading, formatDate } from '../shared/utils';

type ColabDetailsOverviewProps = { colabEntity?: GetColab };

const labels = {
  transcribed: 'Transcribed Feedback',
  translated: 'Translated Text (EN)',
  timestamp: 'Timestamp',
  vehicleModel: 'Car Model',
  vehicleModelYear: 'Car Model Year',
  systemLanguage: 'System Language',
  vehicleMarket: 'Market',
  age: 'Age',
  email: 'Customer Email Address',
  category: 'Category',
  fromAppName: 'Coming from which App',
  fromAppVersion: 'App Version',
  ihuSoftwareVersion: 'IHU SW Version',
  dhuVersion: 'DHU SW Version',
  partitionKey: 'PartitionKey',
  rowKey: 'RowKey',
  errors: 'Errors',
  etag: 'Etag',
  reference: 'Reference',
};

function ColabDetailsOverview({ colabEntity }: ColabDetailsOverviewProps) {
  function hasAccess(scope: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }

  function labelValueHandler(key: string, value: string | number) {
    if (key === 'timestamp') return formatDate(value.toString());
    if (key === 'vehicleModel' && value === 'CX11') return 'CX11 A1';
    else return value;
  }

  function inCarDeviceTypeHandler(orderedRenamedRecord: { [key: string]: string | number }) {
    if (orderedRenamedRecord['Car Model'] === 'CX11 A1') delete orderedRenamedRecord['DHU SW Version'];
    else delete orderedRenamedRecord['IHU SW Version'];
  }

  if (colabEntity) {
    const orderedRenamedRecord: { [key: string]: string | number } = {};

    Object.entries(labels).map(([key, value]) => {
      if (key in colabEntity) {
        orderedRenamedRecord[value] = labelValueHandler(key, colabEntity[key as keyof GetColab]);
      }
    });

    inCarDeviceTypeHandler(orderedRenamedRecord);

    return (
      <DetailsSection>
        <DetailsSectionHeading>Details</DetailsSectionHeading>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {Object.entries(orderedRenamedRecord).map(([labelName, value]) => {
            if ((labelName === 'Age' || labelName === 'Customer Email Address') && hasAccess('COLAB_FULL_ACCESS')) {
              return (
                <ColDefinitionDetails key={labelName} label={labelName}>
                  {value}
                </ColDefinitionDetails>
              );
            } else if (labelName !== 'Age' && labelName !== 'Customer Email Address') {
              return (
                <ColDefinitionDetails key={labelName} label={labelName}>
                  {value}
                </ColDefinitionDetails>
              );
            }
          })}
        </dl>
      </DetailsSection>
    );
  } else {
    return <div className="text-center text-2xl m-8 text-gray-500">No Record Found</div>;
  }
}

export default ColabDetailsOverview;
